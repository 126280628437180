import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { environment } from "src/environments/environment";
import { authGuard } from "./core/guards/auth.guard";

const routes: Routes = [
  ...(environment.maintenanceMode
    ? [{ path: "**", redirectTo: "/maintenance" }]
    : []),
  {
    path: "buy",
    loadChildren: () =>
      import("./features/checkout/checkout.module").then(
        (m) => m.CheckoutModule,
      ),
    canActivate: [authGuard],
  },
  {
    path: "manage",
    loadChildren: () =>
      import("./features/myaccount/myaccount.module").then(
        (m) => m.MyAccountModule,
      ),
    canActivate: [authGuard],
  },
  {
    path: "maintenance",
    loadChildren: () =>
      import("./features/maintenance/maintenance.module").then(
        (m) => m.MaintenanceModule,
      ),
  },
  { path: "**", redirectTo: "/manage" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
      scrollOffset: [0, 40],
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
