import type { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";

export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.identityUrl,
  redirectUri: window.location.origin + "/account/auth/callback",
  strictDiscoveryDocumentValidation: false,
  clientId: "4c118084-50d7-de64-1e56-ae67ee917ea8",
  responseType: "code",
  scope: "openid email profile offline_access admin:account",
  showDebugInformation: !environment.production,
  logoutUrl: window.location.origin + "/auth/signout",
};
