import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { filter } from "rxjs/operators";
import { IdToken } from "src/app/core/models/IdToken";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private userSubject: BehaviorSubject<IdToken | null> = new BehaviorSubject(
    null,
  );

  public user$: Observable<IdToken | null> = this.userSubject.asObservable();

  public get user(): IdToken | null {
    return this.oauthService.getIdentityClaims() as any;
  }
  public get accessToken() {
    return this.oauthService.getAccessToken();
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private oauthService: OAuthService,
  ) {
    this.oauthService.events
      .pipe(filter((event) => event.type === "token_refreshed"))
      .subscribe(() => {
        this.userSubject.next(this.user);
      });
  }

  public login(targetUrl?: string) {
    this.oauthService.initCodeFlow(targetUrl || this.router.url);
  }

  public async logout() {
    try {
      await this.http
        .post(`${environment.identityUrl}/logout`, null, {
          withCredentials: true,
          headers: new HttpHeaders({
            "X-Skip-JWT-Interceptor": "true",
            "X-Error-Interceptor-Skip-Sign-Out": "true",
          }),
        })
        .toPromise();
    } catch (err) {
      if (err?.status !== 401) {
        throw new Error("Failed to log out");
      }
    }
    this.oauthService.logOut();
  }
}
