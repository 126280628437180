import { APP_INITIALIZER, NgModule } from "@angular/core";
import { Router } from "@angular/router";
import { AuthConfig, OAuthModule, OAuthService } from "angular-oauth2-oidc";
import { authInitializerFactory } from "./auth-initializer.factory";
import { AuthRoutingModule } from "./auth-routing.module";
import { authCodeFlowConfig } from "./auth.config";
import { AuthenticationService } from "./services/authentication.service";

@NgModule({
  imports: [AuthRoutingModule, OAuthModule.forRoot()],
  providers: [
    AuthenticationService,
    {
      provide: AuthConfig,
      useValue: authCodeFlowConfig,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (router: Router, oauthService: OAuthService) =>
        authInitializerFactory(router, oauthService),
      deps: [Router, OAuthService],
      multi: true,
    },
  ],
})
export class AuthModule {}
