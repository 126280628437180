import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AuthenticationService } from "src/app/modules/auth/services/authentication.service";

const InterceptorSkipHeader = "X-Skip-JWT-Interceptor";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      request.headers.has(InterceptorSkipHeader) ||
      request.headers.has("Authorization")
    ) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(
        request.clone({
          headers,
        }),
      );
    }

    const token = this.authenticationService.accessToken;

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request);
  }
}
