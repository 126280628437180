import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { AuthenticationService } from "src/app/modules/auth/services/authentication.service";

const InterceptorSkipHeader = "X-Error-Interceptor-Skip-Sign-Out";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(
        request.clone({
          headers,
        }),
      );
    }

    return next
      .handle(request)
      .pipe(catchError(this.onError.bind(this, request)));
  }

  onError(request, err) {
    const { status, error } = err;

    if (
      status === 401 ||
      (status === 400 && error.error && error.error_description)
    ) {
      if (
        this.authenticationService.user &&
        !request.headers.has(InterceptorSkipHeader)
      ) {
        this.authenticationService.logout();
      }

      const authError = new AuthError(
        error.error_description || error.message,
        { cause: error },
      );
      authError.status = status;
      return throwError(authError);
    }

    const httpError = new HttpError(error.message);
    httpError.status = status;
    return throwError(httpError);
  }
}

class HttpError extends Error {
  status: number;

  constructor(...params) {
    super(...params);
  }
}

class AuthError extends HttpError {
  status: number;

  constructor(...params) {
    super(...params);
  }

  toString() {
    return this.message || `Auth error: status code ${this.status}`;
  }
}
