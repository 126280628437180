import { Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";

export const authInitializerFactory =
  (router: Router, oauthService: OAuthService) => async () => {
    await oauthService.loadDiscoveryDocument();
    await oauthService.tryLoginCodeFlow({ disableNonceCheck: true });

    oauthService.setupAutomaticSilentRefresh();

    let stateUrl = oauthService.state;
    if (stateUrl) {
      if (!stateUrl.startsWith("/")) {
        stateUrl = decodeURIComponent(stateUrl);
      }
      router.navigateByUrl(stateUrl);
    }
  };
