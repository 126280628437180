import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { AuthenticationService } from "src/app/modules/auth/services/authentication.service";

export const authGuard: CanActivateFn = (route, state) => {
  const oauthService = inject(OAuthService);
  const authenticationService = inject(AuthenticationService);

  if (!oauthService.hasValidAccessToken()) {
    authenticationService.login();
    return false;
  }
  return true;
};
